import React from "react";
import { graphql, withPrefix } from "gatsby";
import LazyHero from "react-lazy-hero";
import { trackCustomEvent } from "gatsby-plugin-google-analytics";
import SEO from "../../components/SEO";
import Layout from "../../layouts/index";
import HeroContentContainer from "../../components/HeroContentContainer";

const renderSponsor = ({ node }) => (
  <div
    key={node.id}
    className="col-12 col-lg-6 mb-2"
    onClick={() => {
      trackCustomEvent({
        category: "Partners & Judges",
        action: "Sponsor Click",
        label: `${node.title} - ${node.link}`,
      });
    }}
  >
    <a href={node.link} target="_blank" rel="noopener noreferrer">
      <div className="sponsor">
        {node.image && (
          <div className="sponsor-image">
            <img src={withPrefix(node.image)} alt="" />
          </div>
        )}
      </div>
    </a>
  </div>
);

const renderPartner = ({ node }) => (
  <div
    key={node.id}
    className="col-6 col-md-6 col-lg-4 mb-2"
    onClick={() => {
      trackCustomEvent({
        category: "Partners & Judges",
        action: "Partner Click",
        label: `${node.title} - ${node.link}`,
      });
    }}
  >
    <a href={node.link} target="_blank" rel="noopener noreferrer">
      <div className="partner">
        {node.image && (
          <div className="partner-image">
            <img src={withPrefix(node.image)} alt="" />
          </div>
        )}
      </div>
    </a>
  </div>
);

const renderJudge = ({ node }) => (
  <div
    key={node.id}
    className="col-12 col-md-6 pb-2"
    onClick={() => {
      trackCustomEvent({
        category: "Partners & Judges",
        action: "Judge Click",
        label: `${node.title}`,
      });
    }}
  >
    <div className="judge">
      {node.image && (
        <div className="judge-image">
          <img src={withPrefix(node.image)} alt="" />
        </div>
      )}
      <h4 className="text-dark-blue mb-0">{node.name}</h4>
      <h5 className="font-weight-bold mt-0 judge-title">{node.title}</h5>
      <p className="judge-excerpt">{node.excerpt}</p>
    </div>
  </div>
);

const renderAdvisor = ({ node }) => (
  <div key={node.id} className="col-12 col-sm-6 col-md-4 col-lg-3 pb-2">
    <div className="advisor">
      {node.image && (
        <div className="advisor__image">
          <img src={withPrefix(node.image)} alt="" />
        </div>
      )}
      <h4 className="text-dark-blue mb-0">{node.name}</h4>
      <h5 className="font-weight-bold mt-0">
        {node.titles.map(t => (
          <React.Fragment key={`${node.id}-${t.title}`}>
            <span className="advisor__title">{t.title}</span>
            <br />
            <span className="advisor__company">{t.company}</span>
            <br />
          </React.Fragment>
        ))}
      </h5>
    </div>
  </div>
);

const WhoWeAre = ({ data, location }) => {
  const sponsors = data.allAdditionalInformationSponsorsJson.edges;
  const partners = data.allAdditionalInformationPartnersJson.edges;
  const judges = data.allAdditionalInformationJudgesJson.edges;
  const advisors = data.allAdditionalInformationAdvisorsJson.edges;

  return (
    <Layout bodyClass="page-how-to-apply" location={location}>
      <SEO title="Our Partners" />
      <LazyHero
        className="hero--secondary"
        imageSrc={withPrefix("/heroes/partners-and-judges.jpg")}
        isCentered={false}
        minHeight="100px"
        opacity={0.2}
        color="#25a9e0"
      >
        <HeroContentContainer>
          <h1>Our Partners</h1>
        </HeroContentContainer>
      </LazyHero>
      <div className="container pt-5 pt-md-7">
        <h2 className="font-weight-bolder">Rise Prize Sponsors</h2>
        <p>Our sponsors fund the Prize and its development.</p>
        <div className="row justify-content-center">
          {sponsors.map(edge => renderSponsor(edge))}
        </div>
      </div>
      <div className="container pt-5 pt-md-7 pb-5">
        <h2 className="font-weight-bolder">Rise Prize Partners</h2>
        <p className="mb-3">
          Our partners help increase awareness of the Prize—and more
          importantly—the unique needs and strengths of student parents.
        </p>
        <div className="row justify-content-center">
          {partners.map(edge => renderPartner(edge))}
        </div>
      </div>
      <div className="bg-light-blue">
        <div className="container pt-5 pt-md-7">
          <div className="px-md-2">
            <h2 className="text-md-left font-weight-bolder">
              Rise Prize Judges
            </h2>
            <p>Our judges select the winners from our prize finalists.</p>
          </div>
          <div className="row">{judges.map(edge => renderJudge(edge))}</div>
        </div>
      </div>
      <div className="">
        <div className="container pt-5 pt-md-7">
          <div className="px-md-2 pb-5">
            <h2 className="text-md-left font-weight-bolder">
              Meet Our Advisors
            </h2>
          </div>
          <div className="row adivsors mb-5">
            {advisors.map(edge => renderAdvisor(edge))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allAdditionalInformationSponsorsJson {
      edges {
        node {
          id
          title
          image
          link
        }
      }
    }
    allAdditionalInformationPartnersJson {
      edges {
        node {
          id
          title
          image
          link
        }
      }
    }
    allAdditionalInformationJudgesJson {
      edges {
        node {
          id
          name
          title
          image
          excerpt
        }
      }
    }
    allAdditionalInformationAdvisorsJson {
      edges {
        node {
          id
          name
          titles {
            title
            company
          }
          image
        }
      }
    }
  }
`;

export default WhoWeAre;
